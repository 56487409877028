"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

// var _reactJsPagination = _interopRequireDefault(require("react-js-pagination"));

// var _SearchResultsMessage = _interopRequireDefault(
//   require("../../components/SearchResultsMessage")
// );

// var _SearchListItem = _interopRequireDefault(
//   require("../../components/SearchListItem")
// );

// var _SearchPaginationResults = _interopRequireDefault(
//   require("../../components/SearchPaginationResults")
// );

// var _SearchPageSizer = _interopRequireDefault(
//   require("../../components/SearchPageSizer")
// );

var _SearchInput = _interopRequireDefault(
  require("../../components/SearchInput")
);

var _search_defaults = require("../../services/search/search_defaults");

var _reactContentLoader = require("react-content-loader");

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(
    nodeInterop
  ) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (
    obj === null ||
    (_typeof(obj) !== "object" && typeof obj !== "function")
  ) {
    return { default: obj };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor =
    Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor
        ? Object.getOwnPropertyDescriptor(obj, key)
        : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj["default"] = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}

var SearchContentBar = function SearchContentBar() {
  var _useContext = (0, _react.useContext)(_search_defaults.SearchDispatch),
    searchState = _useContext.searchState,
    dispatch = _useContext.dispatch;

  var items = searchState.items,
    fulltext = searchState.fulltext,
    loading = searchState.loading;
  return _react["default"].createElement(
    "div",
    {
      className: "dc-results-list col-md-12 col-sm-12",
    },
    items &&
      _react["default"].createElement(
        "div",
        null,
        _react["default"].createElement(_SearchInput["default"], {
          placeholder: "Type your search term here",
          showSubmit: false,
          srOnly: true,
          value: fulltext,
          onChangeFunction: dispatch,
          onResetFunction: function onResetFunction() {
            return dispatch({
              type: "RESET_FULLTEXT",
            });
          },
          resetContent: "Clear text",
        }),
        loading
          ? _react["default"].createElement(
              "div",
              null,
              _react["default"].createElement(_reactContentLoader.List, null)
            )
          : ""
      )
  );
};

var _default = SearchContentBar;
exports["default"] = _default;
