import React, { useEffect, useState } from "react"
import axios from "axios"
import ReactGA from "react-ga"
import { Link } from "@reach/router"
import Layout from "../../components/Layout"
import config from "../../assets/config"
import ResourceTemplate from "../../components/Resource"

import { Text, Organization, Table, Tags, TopicIcon, TopicWrapper } from "../../resources/data-catalog-components"

let orgs = []
const Dataset = ({ id, location }) => {
  const { state } = location
  const [item, setItem] = useState(state && state.dataset ? state.dataset : {})
  const [hasWindow, checkForWindow] = useState(false)
  const [isInfographic, setIsInfographic] = useState(false)

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  useEffect(() => {
    if (window !== undefined) {
      checkForWindow(true)
    }
    async function getItem() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${id}?show-reference-ids`)
      setItem(data)
      if (data["@type"] === "dcat:Infografis" && data.distribution[0].data.url !== "") {
        setIsInfographic(true)
      }
    }
    if (!state || !state.dataset) {
      getItem()
    }
  }, [id, state])

  const orgName = "publisher" in item && item.publisher ? item.publisher.data.name : ""
  const orgDetails = orgs.filter((org) => orgName === org.name)
  const orgImage = orgDetails.length > 0 && orgDetails[0].imageUrl ? orgDetails[0].imageUrl : null
  const orgDesc = orgDetails.length > 0 && orgDetails[0].description ? orgDetails[0].description : ""
  let renderOrg
  if (orgDetails.length > 0 && orgDetails[0].imageUrl) {
    renderOrg = <Organization name={orgName} imageUrl={orgImage} description={orgDesc} />
  } else {
    renderOrg = <Organization name={orgName} description={orgDesc} />
  }

  const tag = "keyword" in item ? item.keyword : []
  const theme = "theme" in item ? item.theme : []

  function themes(theme) {
    if (!theme) {
      return null
    } else {
      return theme.map((topic) => {
        return <TopicWrapper component={TopicIcon} topic={topic.data} key={topic.identifier} />
      })
    }
  }

  const labelsT3 = {}
  const valuesT3 = {}

  if (orgName && orgName.length > 0) {
    labelsT3.publisher = { label: "Publisher" }
    valuesT3.publisher = orgName
  }
  if ("identifier" in item && item.identifier) {
    labelsT3.identifier = { label: "Identifier" }
    valuesT3.identifier = item.identifier
  }
  if ("issued" in item && item.issued) {
    labelsT3.issued = { label: "Issued" }
    valuesT3.issued = item.issued
  }
  if ("modified" in item && item.modified) {
    labelsT3.modified = { label: "Last Update" }
    valuesT3.modified = item.modified
  }
  if ("license" in item && item.license) {
    labelsT3.license = { label: "License" }
    valuesT3.license = `<a href="${item.license}">${item.license}</a>`
  }
  if ("contactPoint" in item && item.contactPoint && item.contactPoint.fn) {
    labelsT3.contact = { label: "Contact" }
    valuesT3.contact = item.contactPoint.fn
  }
  if ("contactPoint" in item && item.contactPoint && item.contactPoint.hasEmail) {
    labelsT3.email = { label: "Contact E-mail" }
    valuesT3.email = `<a href="${item.contactPoint.hasEmail}">${item.contactPoint.hasEmail}</a>`
  }
  if ("accessLevel" in item && item.accessLevel) {
    labelsT3.access = { label: "Public Access Level" }
    valuesT3.access = item.accessLevel
  }
  if ("landingPage" in item && item.landingPage) {
    labelsT3.homepage = { label: "Homepage URL" }
    valuesT3.homepage = `<a href="${item.landingPage}">${item.landingPage}</a>`
  }

  return (
    <div class="dataset">
      <Layout title={`Dataset - ${item.title}`}>
        <div class="breadcrumb-container-outer">
          <div class="container breadcrumb-container">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to={`/`}>Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <Link to={`/search`}>Dataset</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {item.identifier}
                </li>
              </ol>
            </nav>
            <h1>{item.title}</h1>
          </div>
        </div>
        <div className={`dc-dataset-page ${config.container}`}>
          <div className="row">
            <div className="col-md-12 dataset-info">
              <div class="row">
                <div class="col-md-4 datasetLastUpdate">
                  <h3> Update Terakhir </h3>
                  {item.modified}
                </div>

                {/* <div class="col-md-3 datasetOrg">
                  <h3> Organisasi </h3>
                  <a style={{ fontWeight: "bold", color: "#FF5500" }} href={"/search?publisher__name=" + orgName}>
                    {orgName}{" "}
                  </a>
                </div> */}

                <div class="col-md-4">
                  <h3> Topik </h3>
                  <Tags tags={theme} path="/search?theme=" label="Tags" />
                </div>

                <div class="col-md-4">
                  <h3> Tags </h3>
                  <Tags tags={tag} path="/search?keyword=" label="Tags" />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="datasetJudul" style={{ marginTop: "-30px", marginBottom: "30px" }}>
                <h2 class="header_dataset" style={{ marginTop: "30px", marginBottom: "10px" }}>
                  Judul
                </h2>
                <Text value={item.title} />
              </div>
              <h2 class="header_dataset" style={{ marginTop: "30px", marginBottom: "10px" }}>
                Deskripsi
              </h2>
              <Text value={item.description} />
              <h2 style={{ marginTop: "30px", marginBottom: "10px" }}>Sumber Data</h2>
              Apabila anda membutuhkan data dalam format json, dapat mengunjungi halaman{" "}
              <b>
                <Link to={`/dataset/${item.identifier}/api`} state={{ dataset: { ...item } }}>
                  {"API "}
                </Link>
              </b>
              .
              {hasWindow &&
                item.distribution &&
                item.distribution.map((dist) => {
                  return <ResourceTemplate key={dist.identifier} resource={dist} identifier={dist.identifier} />
                })}
              {isInfographic && (
                <h2
                  style={{
                    marginTop: "30px",
                    marginBottom: "10px",
                    fontSize: "2.4rem",
                    marginLeft: "5px"
                  }}
                >
                  Pratinjau
                </h2>
              )}
              {isInfographic && <img style={{ width: "95%", border: "5px solid #FFF" }} src={item.distribution[0].data.url} />}
              <h2 style={{ marginTop: "30px", marginBottom: "10px" }}>Informasi Tambahan</h2>
              <Table configuration={labelsT3} data={valuesT3} tableclass="metadata" />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Dataset
