import React from "react"
import ReactGA from "react-ga"
import { useLocation } from "@reach/router"
import { defaultFacets, normalizeItems, sortOptions } from "../../config/search"
import { Search, SearchSidebar, SearchContent, SearchContentBar } from "../../resources/data-catalog-components"
import CalendarComponent from "../../components/CalendarComponent"
import Layout from "../../components/Layout"
import config from "../../assets/config"
import { Link } from "@reach/router"

const SearchTemplate = ({ path }) => {
  const location = useLocation()

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
    <Layout title="Search">
      <div class="breadcrumb-container-outer">
        <div class="container breadcrumb-container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to={`/`}>Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Dataset
              </li>
            </ol>
          </nav>
          <h1>Dataset</h1>
        </div>
      </div>
      <div className={`dc-page ${config.container}`}>
        <Search searchEndpoint={`${process.env.REACT_APP_ROOT_URL}/search`} defaultFacets={defaultFacets} sortOptions={sortOptions} setSearchUrl={true} path={path} location={location} normalize={normalizeItems}>
          <div className="row">
            <SearchContentBar />
            <div class="col-md-4">
              <SearchSidebar />
              <CalendarComponent />
            </div>
            <div class="col-md-8">
              <SearchContent />
            </div>
          </div>
        </Search>
      </div>
    </Layout>
  )
}

export default SearchTemplate
