var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));
var _propTypes = _interopRequireDefault(require("prop-types"));
var _router = require("@reach/router");
var _TopicIcon = _interopRequireDefault(require("../../templates/TopicIcon"));

function InfographicListItem(_ref) {
  var image = _ref.image,
    link = _ref.link,
    title = _ref.title,
    identifier = _ref.identifier,
    description = _ref.description,
    onClickAction = _ref.onClickAction;
  var content = "";

  if (image) {
    // Image provided as a url.
    content = _react["default"].createElement(
      "div",
      {
        to: link,
        className: "dc-infographic-icon-link",
      },
      _react["default"].createElement("img", {
        src: image,
        alt: title,
        "data-target": "#exampleModal",
        onClick: () => {
          onClickAction([image, title, description]);
        },
      }),
      _react["default"].createElement(
        "div",
        { className: "infographicTitle" },
        title
      ),
      _react["default"].createElement(
        "div",
        { className: "infographicDescription" },
        description
      )
    );
  } else {
    // Image provided by custom component.
    content = _react["default"].createElement(
      _router.Link,
      {
        to: link,
        className: "dc-infographic-icon-link",
      },
      _react["default"].createElement(_TopicIcon["default"], {
        title: title,
      }),
      _react["default"].createElement(
        "div",
        { className: "infographicTitle" },
        title
      ),
      _react["default"].createElement(
        "div",
        { className: "infographicDescription" },
        description
      )
    );
  }

  return _react["default"].createElement(
    "li",
    {
      key: identifier,
    },
    content
  );
}

InfographicListItem.defaultProps = {
  link: "",
  image: "",
  size: "",
  color: "",
  identifier: "",
};
InfographicListItem.propTypes = {
  title: _propTypes["default"].string.isRequired,
  link: _propTypes["default"].string,
  image: _propTypes["default"].string,
  size: _propTypes["default"].string,
  total: _propTypes["default"].string,
  color: _propTypes["default"].string,
  identifier: _propTypes["default"].string,
};
var _default = InfographicListItem;
exports["default"] = _default;
