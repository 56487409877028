import React from "react"
import Layout from "../../components/Layout"
import axios from "axios"
import { Table, Space, Tag, Button, Modal } from "antd"
import { Link } from "@reach/router"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import _ from "lodash"

const { confirm } = Modal
axios.defaults.withCredentials = true

const columns = [
  {
    title: "Type",
    dataIndex: "@type",
    key: "type",
    filters: [
      {
        text: "Dataset",
        value: "Dataset"
      },
      {
        text: "Infografis",
        value: "Infografis"
      }
    ],
    onFilter: (value, record) => record["@type"].indexOf(value) === 0,
    sorter: (a, b) => a["@type"].length - b["@type"].length,
    sortDirections: ["descend"]
  },
  {
    title: "Judul",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "Topik",
    key: "theme",
    dataIndex: "theme",
    render: (theme) => (
      <>
        {theme.map((tag) => {
          return (
            <span className="tags-label" style={{ fontSize: "11px", fontWeight: "400" }}>
              {tag}
            </span>
          )
        })}
      </>
    ),
    width: "25%"
  },
  {
    render: (text, record) => (
      <Space size="middle">
        <Link to={`/manage/edit/` + record.identifier}>
          <Button
            style={{
              borderRadius: "3px",
              borderColor: "#FF5500",
              background: "#FF5500",
              lineHeight: "18px",
              color: "#fff",
              fontSize: "12px"
            }}
          >
            Sunting
          </Button>
        </Link>
      </Space>
    ),
    width: "13%"
  }
]

const Admin = () => {
  const [data, setData] = React.useState([])
  const [initialData, setInitialData] = React.useState([])
  const [selectedRowKeys, setselectedRowKeys] = React.useState([])
  const [reload, setReload] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [keyword, setKeyword] = React.useState(false)
  const [isAuthenticate, setIsAuthenticate] = React.useState(false)

  function onSelectChange(selectedRowKeys) {
    setselectedRowKeys(selectedRowKeys)
  }

  function filterList(event) {
    setKeyword(event.target.value)
  }

  function deleteDataset(ids) {
    let _data = data
    let _newData = []
    try {
      ids.forEach(async (id) => {
        await axios.delete(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${id}?show-reference-ids`)
      })

      _data.forEach((o) => {
        if (!ids.includes(o["key"])) {
          _newData.push(o)
        }
      })

      setData(_newData)
      setInitialData(_newData)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  function showConfirm() {
    if (selectedRowKeys.length > 0) {
      setLoading(true)
      confirm({
        title: "Yakin menghapus dataset id(s) berikut ?",
        icon: <ExclamationCircleOutlined />,
        content: selectedRowKeys.join(", "),
        onOk() {
          deleteDataset(selectedRowKeys)
        },
        onCancel() {
          setLoading(false)
        }
      })
    }
  }

  React.useEffect(() => {
    if (localStorage.getItem("authenticated") === "true") {
      setIsAuthenticate(true)
    }
  }, [])

  React.useEffect(() => {
    let _afterFilter = _.filter(initialData, function (o) {
      return o.title.toLowerCase().includes(keyword.toLowerCase())
    })

    setData(_afterFilter)
  }, [keyword])

  React.useEffect(() => {
    async function getData() {
      let _data = []
      try {
        const { data: rawdata } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/`)

        let i = 1
        rawdata.forEach((o) => {
          let _insertData = {}
          _insertData["key"] = o["identifier"] ? o["identifier"] : ""
          _insertData["@type"] = o["@type"] ? o["@type"].replace("dcat:", "") : ""
          _insertData["title"] = o["title"]
          _insertData["description"] = o["description"].substring(0, 400) + "..."
          _insertData["identifier"] = o["identifier"]
          _insertData["modified"] = o["modified"]
          _insertData["keyword"] = o["keyword"] ? o["keyword"] : []
          _insertData["theme"] = o["theme"] ? o["theme"] : []
          _insertData["publisher"] = o["publisher"] ? o["publisher"].name : ""
          _insertData["distribution"] = o["distribution"]
          _data.push(_insertData)
          i++
        })

        setData(_data)
        setInitialData(_data)
      } catch (e) {
        console.log(e)
      }
    }
    getData()
  }, [reload])

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }
  if (isAuthenticate) {
    return (
      <Layout title="Administration">
        <div class="breadcrumb-container-outer">
          <div class="container breadcrumb-container">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to={`/`}>Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Kelola
                </li>
              </ol>
            </nav>
            <h1>Kelola Dataset</h1>
          </div>
        </div>
        <div class="container">
          <div
            style={{
              float: "left",
              marginTop: "-10px",
              marginBottom: "15px"
            }}
          >
            <Link to={`/manage/add`}>
              <Button
                style={{
                  borderRadius: "3px",
                  height: "42px",
                  background: "#FF5500",
                  borderColor: "#FF5500",
                  lineHeight: "18px",
                  color: "#FFF"
                }}
              >
                Tambah
              </Button>
            </Link>{" "}
            &nbsp;
            <Button
              onClick={showConfirm}
              loading={loading}
              style={{
                borderRadius: "3px",
                height: "42px",
                lineHeight: "18px",
                background: "rgb(132, 45, 2)",
                borderColor: "rgb(132, 45, 2)",
                color: "#eee"
              }}
            >
              &nbsp; Hapus &nbsp;
            </Button>
          </div>
          <div class="dc-search-manage  form-group">
            <input name="inputSearch" id="inputSearch" placeholder="Type your search term here" type="text" class="form-control-lg form-control" onChange={filterList} />
          </div>
          <Table
            rowSelection={rowSelection}
            dataSource={data}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <div style={{ margin: "5px 84px" }}>
                    <table>
                      <tr>
                        <th width="300px">ORGANISASI</th>
                        <th width="300px"> KEYWORD</th>
                        <th> DESKRIPSI</th>
                      </tr>
                      <tr>
                        <td style={{ paddingLeft: "2px", color: "#888" }}>{record.publisher}</td>
                        <td style={{ paddingLeft: "2px", color: "#888" }}>{record.keyword.join(", ")}</td>
                        <td style={{ paddingLeft: "2px", color: "#888" }}>{record.description}</td>
                      </tr>
                    </table>
                  </div>
                )
              },
              rowExpandable: (record) => record.name !== "Not Expandable"
            }}
          />
        </div>
      </Layout>
    )
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "22%" }}>
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
}

export default Admin
