import React from "react"
import axios from "axios"
import ReactGA from "react-ga"
import { Blocks, Hero, StatBlock } from "../../resources/data-catalog-components"
import Layout from "../../components/Layout"
import FeaturedDatasets from "../../components/FeaturedDatasets"
import { Doughnut, Bar } from "react-chartjs-2"
const blockStat = [
  {
    content: "Dataset",
    title: "-",
    icon: "Dataset",
    ref: "1"
  },
  {
    content: "Filedata",
    title: "-",
    icon: "download",
    ref: "2"
  },
  {
    content: "Topik",
    title: "-",
    icon: "database",
    ref: "3"
  }
]

const chartInitialData = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [],
      backgroundColor: ["#FF5500", "#F4E64A", "#F0A10D", "#F57404", "#F43205", "#9E0F01", "#5A0500", "#FF696A", "#E87360", "#FF8B5C", "#E89B60", "#FFC069"],
      borderWidth: 0
    }
  ]
}

const pieOptions = {
  responsive: true,
  hoverOffset: 20,
  radius: 165,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      maxWidth: 60,
      align: "center"
    },
    title: {
      display: false,
      text: ""
    }
  }
}

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 0
    }
  },
  responsive: true,
  plugins: {
    legend: false,
    title: {
      display: false,
      text: ""
    }
  }
}

const Home = () => {
  const [datasets, setDatasets] = React.useState(null)
  const [themes, setThemes] = React.useState([])
  const [items, setItems] = React.useState([])
  const [fDatasets, setFDatasets] = React.useState([])
  const [chartOrg, setChartOrg] = React.useState([chartInitialData])
  const [chartTheme, setChartTheme] = React.useState([chartInitialData])
  const [chartTopic, setChartTopic] = React.useState([])
  const [blockStatData, setBlockStatData] = React.useState([blockStat])

  // chartOrg
  React.useEffect(() => {
    async function getChartOrg() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search/facets?facets=publisher__name`)

      var chartDataLabel = []
      var chartDataDatasetData = []
      data.facets.forEach((o) => {
        chartDataLabel.push(o.name)
        chartDataDatasetData.push(parseInt(o.total))
      })

      var updatePieOrg = chartInitialData
      updatePieOrg.labels = chartDataLabel
      updatePieOrg.datasets[0].label = "# datasets dari Organisasi"
      updatePieOrg.datasets[0].data = chartDataDatasetData

      setChartOrg(updatePieOrg)
    }
    getChartOrg()
  }, [chartOrg])

  // chartTheme
  React.useEffect(() => {
    async function getTheme() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search/facets?facets=theme`)

      var totalOrg = 0
      var totalDataset = 0
      var chartDataLabel = []
      var chartDataDatasetData = []
      var i = 1
      data.facets.forEach((o) => {
        chartDataLabel.push(o.name)
        chartDataDatasetData.push(parseInt(o.total))
        totalDataset = parseInt(totalDataset) + parseInt(o.total)
      })

      blockStat[2].title = data.facets.length

      var updatePieOrg = chartInitialData
      updatePieOrg.labels = chartDataLabel
      updatePieOrg.datasets[0].label = " tema datasets"
      updatePieOrg.datasets[0].data = chartDataDatasetData

      setChartTheme(updatePieOrg)
    }

    getTheme()

    // GA
    ReactGA.pageview(window.location.pathname)
  }, [])

  React.useEffect(() => {
    async function getDatasets() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items?show-reference-ids`)

      var totalFile = 0
      data.forEach((o) => {
        if (o.distribution) {
          totalFile = parseInt(totalFile) + o.distribution.length
        }
      })
      blockStat[1].title = totalFile
      blockStat[0].title = data.length
      setBlockStatData(blockStat)

      setDatasets(data)
    }
    async function getThemes() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/theme/items`)
      setThemes(data)
    }
    if (datasets === null) {
      getDatasets()
      getThemes()
    }
    if (datasets) {
      const orderedDatasets = datasets.sort(function (a, b) {
        return a.title - b.title
      })

      setFDatasets(orderedDatasets.length > 5 ? orderedDatasets.slice(orderedDatasets.length - 5, orderedDatasets.length) : orderedDatasets)
    }
  }, [datasets])

  React.useEffect(() => {
    setItems(
      themes.map((x) => {
        let item = {
          identifier: x.identifier,
          ref: `search?theme=${x.data}`,
          title: x.data,
          size: "100"
        }
        return item
      })
    )
  }, [themes])

  return (
    <Layout title="Home">
      <div className="home-page">
        <div class="main-home-banner d-flex flex-fill flex-row">
          <div class="flex-fill col-md-8 row align-content-center" style={{ color: "#1E4357" }}>
            <div class="col-md-4"></div>
            <div class="col-md-7 hidden-sm">
              <div class="d-flex flex-column flex-fill">
                <div class="p-1 mr-auto" style={{ marginTop: "90px" }}>
                  <div class="header_data_tbk" id="opendata-count">
                    Data Terbuka,
                  </div>
                </div>
                <div
                  style={{
                    width: "30px",
                    marginLeft: "5px",
                    borderBottom: "2px solid #FF5500",
                    animation: "blinker 1s linear infinite",
                    marginTop: "-5px"
                  }}
                ></div>

                <div class="p-3 mr-auto" style={{ marginTop: "10px" }}>
                  <div
                    class="title"
                    style={{
                      fontSize: "18px",
                      color: "#0d0c22",
                      fontWeight: "100",
                      lineHeight: "32px"
                    }}
                  >
                    Selamat datang di portal Open Data Komisi Pemilihan Umum Republik Indonesia, kami menyediakan data kepemiluan dalam bentuk API serta format lain seperti json, csv, doc, xls, dan pdf.
                  </div>
                </div>

                <div p-5 class="col-md-12" style={{ marginTop: "20px" }}>
                  <Hero tags={chartTopic} />
                </div>
              </div>
            </div>
          </div>
          <div class="p-5 col-md-4 d-none d-lg-block" style={{ background: "#FFF", color: "#FFF" }}>
            <img src="frontend/build/sw_ov_intro_hero.svg" width="80%" style={{ marginTop: "-5px", marginLeft: "-60px" }}></img>
          </div>
          <div class="p-5 flex-fill flex-column col-md-1 align-content-center d-none d-lg-block" style={{ background: "#FFF", color: "#FFF" }}></div>
        </div>
        <Blocks items={blockStatData} component={StatBlock} containerClass="" blockClass="StatBlock" />

        <div class="container">
          <div class="row">
            <div class="col-md-7 ">
              <div class="text-center" style={{ marginTop: "50px", marginBottom: "30px" }}>
                <h2> Dataset Terbaru</h2>
              </div>
              <FeaturedDatasets datasets={fDatasets} />
            </div>

            <div class="col-md-5 d-none d-md-block">
              <div class="text-center" style={{ marginTop: "50px" }}>
                <h2> Dashboard Data</h2>
              </div>
              <Doughnut
                style={{
                  marginTop: "20px"
                }}
                data={chartOrg}
                options={pieOptions}
              />
              <Bar height="300px" style={{ marginTop: "20px" }} data={chartTheme} options={options} />

              {/* <Bar
                height="500px"
                style={{ marginTop: "20px" }}
                data={chartTopic}
                options={options}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home
