import React from "react"
import Layout from "../../../components/Layout"
import { Link, navigate } from "@reach/router"
import axios from "axios"
import { Select, Input, Button, Radio, Upload, message } from "antd"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import "../../../resources/antd/antd.css"
const { TextArea } = Input
var md5 = require("md5")
const sitekey = "6Ldzyj4cAAAAAEs2CaKExBF-4MBJ3jjayduWhu0z"

axios.defaults.withCredentials = true

const optionsType = [
  { label: "Dataset", value: "dcat:Dataset" },
  { label: "Infografis", value: "dcat:Infografis" }
]

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-")
}

function chunk(str, n) {
  var ret = []
  var i
  var len

  for (i = 0, len = str.length; i < len; i += n) {
    ret.push(str.substr(i, n))
  }

  return ret
}

const nowDays = formatDate(Date.now())
const identifier = chunk(md5(Date.now()), 9).join("-")
const { Option } = Select

const Add = () => {
  const initialFileJson = {
    "@type": "dcat:Distribution",
    name: "-",
    status: "done",
    downloadURL: "-",
    mediaType: "text/csv",
    format: "csv",
    title: "nama file",
    url: "-"
  }

  const initialDataInput = {
    "@type": "dcat:Dataset",
    identifier: identifier,
    title: "",
    description: "",
    keyword: [],
    theme: [],
    accessLevel: "public",
    issued: nowDays,
    modified: nowDays, // pada saat edit, nilai ini tetap
    publisher: {
      "@type": "org:Organization",
      name: "kpu" // pada saat edit, tambahkan default value di template
    }
  }

  const [inputDatasetData, setInputDatasetData] = React.useState(initialDataInput)
  const [initialOrg, setInitialOrg] = React.useState([])
  const [isAuthenticate, setIsAuthenticate] = React.useState(false)
  const [initialTopic, setInitialTopic] = React.useState([])
  const [initialKeyword, setInitialKeyword] = React.useState([])
  const [type, setType] = React.useState(inputDatasetData["@type"])
  const [org, setOrg] = React.useState(inputDatasetData.publisher.name)
  const [title, setTitle] = React.useState(inputDatasetData.title)
  const [description, setDescription] = React.useState(inputDatasetData.description)
  const [topic, setTopic] = React.useState(inputDatasetData.theme)
  const [keyword, setKeyword] = React.useState(inputDatasetData.keyword)
  const [isAddDataset, setIsAddDataset] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [fileList, setFileList] = React.useState([])
  const [changeListener, setChangeListener] = React.useState()
  const [token, setToken] = React.useState()

  async function beforeUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/vnd.ms-excel" ||
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "text/csv" ||
      file.type === "application/pdf"
    if (!isJpgOrPng) {
      message.error("Aplikasi hanya menerima format csv, jpg, png, pdf, doc, docx dan xlsx.")
    }
    const isLt2M = file.size / 1024 / 1024 < 10
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!")
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(sitekey, { action: "submit" }).then((token) => {
        var FormData = require("form-data")
        var data = new FormData()
        data.append("files", file)
        data.append("token", token)

        var config = {
          method: "post",
          url: process.env.REACT_APP_UPLOAD_URL,
          headers: {
            "Content-Type": "multipart/form-data"
          },
          data: data
        }

        axios(config)
          .then(function (result) {
            setLoading(false)
            let fileJson = initialFileJson
            let _fileList = fileList
            fileJson.downloadURL = result.data.data.url
            fileJson.url = result.data.data.url
            fileJson.name = file.name.split(".")[0]
            fileJson.title = file.name.split(".")[0]
            let x = file.name.split(".")
            let xLength = x.length - 1
            fileJson.format = file.name.split(".")[xLength]
            fileJson.mediaType = file.type

            _fileList.push(fileJson)

            setFileList(_fileList)
            setChangeListener(Date.now())
            return
          })
          .catch(function (error) {
            console.log(error)
          })
      })
    })

    return isJpgOrPng && isLt2M
  }

  function handleUploadFile(info) {
    if (info.file.status === "uploading") {
      setLoading(true)
      return
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  React.useEffect(() => {
    if (localStorage.getItem("authenticated") === "true") {
      setIsAuthenticate(true)
    }
  }, [])

  React.useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${sitekey}`, function () {})
  }, [])

  React.useEffect(() => {
    async function getTopic() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search/facets?facets=theme`)
      var initialTopic = []
      data.facets.forEach((o) => {
        initialTopic.push(<Option key={o.name}>{o.name}</Option>)
      })

      setInitialTopic(initialTopic)
    }
    getTopic()
  }, [])

  React.useEffect(() => {
    async function getKeyword() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search/facets?facets=keyword`)
      var initialKeyword = []
      data.facets.forEach((o) => {
        initialKeyword.push(<Option key={o.name}>{o.name}</Option>)
      })

      setInitialKeyword(initialKeyword)
    }
    getKeyword()
  }, [])

  React.useEffect(() => {
    async function getOrg() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search/facets?facets=publisher__name`)
      var initialOrg = []
      data.facets.forEach((o) => {
        initialOrg.push(<Option key={o.name}>{o.name}</Option>)
      })

      setInitialOrg(initialOrg)
    }
    getOrg()
  }, [])

  React.useEffect(() => {
    if (isAddDataset) {
      let _error = false
      let _fileList = fileList
      let _newFileList = []
      let _uuid = chunk(md5(Date.now()), 9).join("-")

      _fileList.forEach((o) => {
        if (o["status"] !== "removed") {
          _newFileList.push(o)
        }
      })

      inputDatasetData["identifier"] = _uuid
      inputDatasetData["distribution"] = _newFileList

      if (inputDatasetData.theme.length < 1) {
        alert("Maaf, Topik Wajib diisi.")
        _error = true
      }

      if (!_error) {
        if (inputDatasetData.keyword.length < 1) {
          alert("Maaf, Tags wajib diisi.")
          _error = true
        }
      }

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items`,
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(inputDatasetData)
      }

      if (!_error) {
        axios(config)
          .then(function (response) {
            setIsAddDataset(false)
            navigate(`/manage`)
          })
          .catch(function (error) {
            setIsAddDataset(false)
            alert("Maaf, mohon cek kembali data anda :( ")
          })
      }
    }
  }, [isAddDataset])

  React.useEffect(() => {
    var updateDatasetData = inputDatasetData
    updateDatasetData["@type"] = type
    updateDatasetData["title"] = title
    updateDatasetData["description"] = description
    updateDatasetData["keyword"] = keyword
    updateDatasetData["theme"] = topic
    if (org) {
      if (org.constructor === Array) {
        updateDatasetData["publisher"].name = org[0]
      } else {
        updateDatasetData["publisher"].name = org
      }
    }

    let _fileList = fileList
    let _newFileList = []

    _fileList.forEach((o) => {
      if (o["status"] !== "removed") {
        _newFileList.push(o)
      }
    })

    inputDatasetData["distribution"] = _newFileList

    setInputDatasetData(updateDatasetData)
  }, [changeListener])

  function handleChangeTopic(e) {
    setTopic(e)
    setChangeListener(Date.now())
  }

  function handleChangeOrg(e) {
    setOrg(e[0])
    setChangeListener(Date.now())
  }

  function handleChangeType(e) {
    setType(e.target.value)
    setChangeListener(Date.now())
  }

  function handleChangeKeyword(e) {
    setKeyword(e)
    setChangeListener(Date.now())
  }

  function onChangeDescription(e) {
    setDescription(e.target.value)
    setChangeListener(Date.now())
  }

  function onChangeTitle(e) {
    setTitle(e.target.value)
    setChangeListener(Date.now())
  }

  const handleAddDataset = () => {
    setIsAddDataset(true)
  }

  const dataDump = <pre>{JSON.stringify(inputDatasetData, null, 2)}</pre>

  if (isAuthenticate) {
    return (
      <Layout title="Form">
        <div class="breadcrumb-container-outer">
          <div class="container breadcrumb-container">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to={`/`}>Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <Link to={`/manage`}>Kelola</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Tambah
                </li>
              </ol>
            </nav>
            <h1>Tambah Data</h1>
          </div>
        </div>
        <div class="container">
          <div className={`dc-page-form`} style={{ marginTop: "5%" }}>
            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">@type</div>
                <div class="label-description">
                  Jenis data di upload, apabila anda memilih <b>Infografis</b>, data yang diupload wajib berupa gambar.
                </div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Radio.Group options={optionsType} onChange={handleChangeType} value={type} optionType="button" />
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Dataset</div>
                <div class="label-description">
                  Unggah satu atau lebih file dataset, pastikan tidak ada kata sandi dalam file yang diunggah. Format <b>.csv</b> meruakan format paling direkomendasikan.
                </div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Upload name="avatar" listType="picture-card" fileList={fileList} beforeUpload={beforeUpload} onChange={handleUploadFile}>
                  {fileList.length >= 5 ? null : uploadButton}
                </Upload>
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Pemilik Data</div>
                <div class="label-description">Pilih Organisasi, Biro, Bagian, atau Satker mana data ini berasal ?</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Select mode="tags" style={{ width: "100%" }} placeholder="Organisasi" onChange={handleChangeOrg}>
                  {initialOrg}
                </Select>
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Judul</div>
                <div class="label-description">Judul berita atau dataset yang akan dipublikasikan.</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Input value={title} placeholder="Judul dataset" onChange={onChangeTitle} />
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Deskripsi</div>
                <div class="label-description">Keterangan mengenai isi berita atau dataset yang akan dibagikan, deskripsi akan membantu dalam pencarian mesin pencari.</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <TextArea value={description} showCount maxLength={1000} placeholder="Keterangan singkat" onChange={onChangeDescription} />
                {/* </TextArea> */}
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Topik</div>
                <div class="label-description">Pilih satu atau lebih topik berita (tema) dari dataset yang akan dipublikasi.</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Select mode="tags" style={{ width: "100%" }} placeholder="Topik atau tema" defaultValue={topic} onChange={handleChangeTopic}>
                  {initialTopic}
                </Select>
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Tags, Keyword</div>
                <div class="label-description">Lengkapi dataset dengan satu atau lebih kata kunci agar mudah ditemukan oleh mesin pencari.</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Select mode="tags" style={{ width: "100%" }} placeholder="Kata kunci pencarian" defaultValue={keyword} onChange={handleChangeKeyword}>
                  {initialKeyword}
                </Select>
              </div>
            </div>

            <Button type="primary" onClick={handleAddDataset}>
              Kirim
            </Button>
            <Button style={{ marginLeft: "10px" }}>Reset</Button>
            <br />
            <br />
            <br />
            {/* {dataDump} */}
          </div>
        </div>
      </Layout>
    )
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "22%" }}>
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
}

export default Add
