var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _router = require("@reach/router");

var _NavBar = _interopRequireDefault(require("../NavBar"));

var Header = function Header(_ref) {
  var link = _ref.link,
    logo = _ref.logo,
    site = _ref.site,
    slogan = _ref.slogan,
    customClasses = _ref.customClasses,
    navItems = _ref.navItems;
  return _react["default"].createElement(
    "div",
    {
      className: "dc-header",
    },
    _react["default"].createElement(
      "div",
      {
        className: customClasses,
      },
      _react["default"].createElement(
        "div",
        {
          className: "branding",
        },
        _react["default"].createElement(
          _router.Link,
          {
            to: link,
            className: "dc-logo",
          },
          _react["default"].createElement("img", {
            src: window.location.origin + "/frontend/build/" + logo,
            alt: "Open Data KPU",
          })
        ),
        _react["default"].createElement(
          "div",
          {
            className: "dc-site-name",
          },
          site &&
            _react["default"].createElement(
              _router.Link,
              {
                to: link,
              },
              site
            ),
          slogan &&
            _react["default"].createElement(
              "div",
              {
                className: "dc-slogan",
              },
              slogan
            )
        )
      )
    ),
    navItems &&
      _react["default"].createElement(_NavBar["default"], {
        navItems: navItems,
        className: customClasses,
      })
  );
};

Header.defaultProps = {
  customClasses: null,
  link: "/",
  logo: "",
  site: "",
  slogan: "",
};
Header.propTypes = {
  customClasses: _propTypes["default"].string,
  link: _propTypes["default"].string,
  logo: _propTypes["default"].string,
  site: _propTypes["default"].string,
  slogan: _propTypes["default"].string,
  navItems: _propTypes["default"].arrayOf(
    _propTypes["default"].shape({
      label: _propTypes["default"].string,
      url: _propTypes["default"].string,
    })
  ).isRequired,
};
var _default = Header;
exports["default"] = _default;
