import React from "react"
import axios from "axios"
import ReactGA from "react-ga"
import { InfographicList, InfographicListItem } from "../../resources/data-catalog-components"
import config from "../../assets/config"
import Layout from "../../components/Layout"
import { Modal, Button } from "react-bootstrap"
import { Link } from "@reach/router"

const Infographics = () => {
  const [datas, setDatas] = React.useState([])
  const [activeImg, setActiveImg] = React.useState([])
  const [activeTitle, setActiveTitle] = React.useState([])
  const [activeDescription, setActiveDescription] = React.useState([])
  const [items, setItems] = React.useState([])
  const [show, setShow] = React.useState(false)
  const handleClose = () => setShow(false)

  const onClickAction = (e) => {
    setActiveImg(e[0])
    setActiveTitle(e[1])
    setActiveDescription(e[2])
    setShow(true)
  }

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  React.useEffect(() => {
    async function getThemes() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items?show-reference-ids`)
      var dataWithTerm = []
      data.forEach((o) => {
        if (o["@type"]) {
          let type = o["@type"]
          if (type.includes("Infografis") || type.includes("infografis")) {
            dataWithTerm.push(o)
          }
        }
      })

      setDatas(dataWithTerm)
    }
    getThemes()
  }, [])

  React.useEffect(() => {
    setItems(
      datas.map((topic) => {
        let item = {
          ref: "/dataset/" + topic.identifier,
          title: topic.title,
          identifier: topic.identifier,
          publisher: topic.publisher.data.name,
          keyword: topic.keyword,
          theme: topic.theme,
          description: topic.description,
          image: topic["distribution"][0]["data"]["downloadURL"]
        }
        return item
      })
    )
  }, [datas])

  return (
    <Layout title="Topics">
      <div class="breadcrumb-container-outer">
        <div class="container breadcrumb-container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to={`/`}>Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Visualisasi
              </li>
            </ol>
          </nav>
          <h1>Visualisasi</h1>
        </div>
      </div>

      <div className={`dc-page ${config.container}`}>
        <div>
          <InfographicList items={items} component={InfographicListItem} onClickAction={onClickAction} />
        </div>
      </div>

      <Modal show={show} size="lg" onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title> {activeTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img style={{ width: "100%" }} src={activeImg} />
          <div class="modal-description">{activeDescription}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} size="lg" style={{ fontSize: "15px !important" }}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default Infographics
