import React from "react"
import Layout from "../../../components/Layout"
import { Link, navigate } from "@reach/router"
import axios from "axios"
import { Select, Input, Button, Radio, Upload, message, Alert } from "antd"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import "../../../resources/antd/antd.css"
const { TextArea } = Input
const sitekey = "6Ldzyj4cAAAAAEs2CaKExBF-4MBJ3jjayduWhu0z"

axios.defaults.withCredentials = true

const optionsType = [
  { label: "Dataset", value: "dcat:Dataset" },
  { label: "Infografis", value: "dcat:Infografis" }
]

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-")
}

const nowDays = formatDate(Date.now())
const { Option } = Select

const Edit = ({ id }) => {
  const initialFileJson = {
    "@type": "dcat:Distribution",
    name: "-",
    status: "done",
    downloadURL: "-",
    mediaType: "text/csv",
    format: "csv",
    title: "nama file",
    url: "-"
  }

  const initialDataInput = {
    "@type": "dcat:Dataset",
    title: "",
    description: "",
    keyword: [],
    theme: [],
    accessLevel: "public",
    issued: nowDays,
    modified: nowDays, // pada saat edit, nilai ini tetap
    publisher: {
      "@type": "org:Organization",
      name: "kpu" // pada saat edit, tambahkan default value di template
    }
  }

  const [initState, setInitState] = React.useState(false)
  const [inputDatasetData, setInputDatasetData] = React.useState(initialDataInput)
  const [isAuthenticate, setIsAuthenticate] = React.useState(false)
  const [initialOrg, setInitialOrg] = React.useState([])
  const [initialTopic, setInitialTopic] = React.useState([])
  const [initialKeyword, setInitialKeyword] = React.useState([])
  const [type, setType] = React.useState("")
  const [org, setOrg] = React.useState([])
  const [title, setTitle] = React.useState("")
  const [description, setDescription] = React.useState("")
  const [topic, setTopic] = React.useState([])
  const [keyword, setKeyword] = React.useState([])
  const [isEditDataset, setIsEditDataset] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [fileList, setFileList] = React.useState([])
  const [changeListener, setChangeListener] = React.useState()
  const [isUpdated, setIsUpdated] = React.useState(false)

  async function beforeUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/vnd.ms-excel" ||
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "text/csv" ||
      file.type === "application/pdf"
    if (!isJpgOrPng) {
      message.error("Aplikasi hanya menerima format csv, jpg, png, pdf, doc, docx dan xlsx.")
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!")
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(sitekey, { action: "submit" }).then((token) => {
        var FormData = require("form-data")
        var data = new FormData()
        data.append("files", file)
        data.append("token", token)

        var config = {
          method: "post",
          url: process.env.REACT_APP_UPLOAD_URL,
          headers: {
            "Content-Type": "multipart/form-data"
          },
          data: data
        }

        axios(config)
          .then(function (result) {
            setLoading(false)
            let fileJson = initialFileJson
            let _fileList = fileList
            fileJson.downloadURL = result.data.data.url
            fileJson.url = result.data.data.url
            fileJson.name = file.name.split(".")[0]
            fileJson.title = file.name.split(".")[0]
            let x = file.name.split(".")
            let xLength = x.length - 1
            fileJson.format = file.name.split(".")[xLength]
            fileJson.mediaType = file.type

            _fileList.push(fileJson)

            setFileList(_fileList)
            setChangeListener(Date.now())
            return
          })
          .catch(function (error) {
            console.log(error)
          })
      })
    })
    return isJpgOrPng && isLt2M
  }

  function handleUploadFile(info) {
    if (info.file.status === "uploading") {
      setLoading(true)
      return
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  React.useEffect(() => {
    if (localStorage.getItem("authenticated") === "true") {
      setIsAuthenticate(true)
    }
  }, [])

  React.useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${sitekey}`, function () {})
  }, [])

  React.useEffect(() => {
    async function getItem() {
      let _org = []
      let _topic = []
      let _keyword = []

      try {
        const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${id}?show-reference-ids`)

        let _fileList = fileList
        data.distribution.forEach((o) => {
          delete o.data["%Ref:downloadURL"]
          _fileList.push(o.data)
        })

        _org.push(data.publisher.data.name)
        data.keyword.forEach((o) => {
          _keyword.push(o.data)
        })

        data.theme.forEach((o) => {
          _topic.push(o.data)
        })

        setType(data["@type"])
        setTitle(data.title)
        setDescription(data.description)
        setOrg(_org)
        setTopic(_topic)
        setKeyword(_keyword)
        setFileList(_fileList)
        setInitState(true)
        var updateDatasetData = inputDatasetData
        updateDatasetData["identifier"] = data.identifier
        updateDatasetData["@type"] = data["@type"]
        updateDatasetData["title"] = data.title
        updateDatasetData["description"] = data.description
        updateDatasetData["keyword"] = _keyword
        updateDatasetData["theme"] = _topic
        updateDatasetData["publisher"].name = data.publisher.data.name
        updateDatasetData["distribution"] = _fileList
        setInputDatasetData(updateDatasetData)
      } catch (e) {
        console.log(e)
      }
    }
    getItem()
  }, [])

  React.useEffect(() => {
    async function getTopic() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search/facets?facets=theme`)
      var initialTopic = []
      data.facets.forEach((o) => {
        initialTopic.push(<Option key={o.name}>{o.name}</Option>)
      })

      setInitialTopic(initialTopic)
    }
    getTopic()
  }, [])

  React.useEffect(() => {
    async function getKeyword() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search/facets?facets=keyword`)
      var initialKeyword = []
      data.facets.forEach((o) => {
        initialKeyword.push(<Option key={o.name}>{o.name}</Option>)
      })

      setInitialKeyword(initialKeyword)
    }
    getKeyword()
  }, [])

  React.useEffect(() => {
    async function getOrg() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search/facets?facets=publisher__name`)
      var initialOrg = []
      data.facets.forEach((o) => {
        initialOrg.push(<Option key={o.name}>{o.name}</Option>)
      })

      setInitialOrg(initialOrg)
    }
    getOrg()
  }, [])

  React.useEffect(() => {
    var updateDatasetData = inputDatasetData
    updateDatasetData["@type"] = type
    updateDatasetData["title"] = title
    updateDatasetData["description"] = description
    updateDatasetData["keyword"] = keyword
    updateDatasetData["theme"] = topic

    if (org) {
      if (org.constructor === Array) {
        updateDatasetData["publisher"].name = org[0]
      } else {
        updateDatasetData["publisher"].name = org
      }
    }

    let _fileList = fileList
    let _newFileList = []

    _fileList.forEach((o) => {
      if (o["status"] !== "removed") {
        _newFileList.push(o)
      }
    })

    inputDatasetData["distribution"] = _newFileList

    setInputDatasetData(updateDatasetData)
  }, [changeListener])

  React.useEffect(() => {
    if (isEditDataset) {
      let _fileList = fileList
      let _newFileList = []

      _fileList.forEach((o) => {
        if (o["status"] !== "removed") {
          _newFileList.push(o)
        }
      })

      inputDatasetData["distribution"] = _newFileList

      var config = {
        method: "put",
        url: `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${id}`,
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(inputDatasetData)
      }

      axios(config)
        .then(function (response) {
          setIsUpdated(true)
          setIsEditDataset(false)
          navigate(`/manage`)
        })
        .catch(function (error) {
          alert("Maaf,terjadi masalah saat menghubungi server :( ")
          setIsEditDataset(false)
        })
    }
  }, [isEditDataset])

  function handleChangeTopic(e) {
    setTopic(e)
    setChangeListener(Date.now())
  }

  function handleChangeOrg(e) {
    setOrg(e[0])
    setChangeListener(Date.now())
  }

  function handleChangeType(e) {
    setType(e.target.value)
    setChangeListener(Date.now())
  }

  function handleChangeKeyword(e) {
    setKeyword(e)
    setChangeListener(Date.now())
  }

  function onChangeDescription(e) {
    setDescription(e.target.value)
    setChangeListener(Date.now())
  }

  function onChangeTitle(e) {
    setTitle(e.target.value)
    setChangeListener(Date.now())
  }

  const handleAddDataset = () => {
    setIsEditDataset(true)
  }

  const handleRemove = (e) => {
    let _fileList = fileList
    let _newFileList = _fileList.filter((n) => n != e)

    setFileList(_newFileList)
    setChangeListener(Date.now())
  }

  const dataDump = <pre>{JSON.stringify(inputDatasetData, null, 2)}</pre>

  if (initState && isAuthenticate) {
    return (
      <Layout title="Form">
        <div class="breadcrumb-container-outer">
          <div class="container breadcrumb-container">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to={`/`}>Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <Link to={`/manage`}>Kelola</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <Link to={`/manage`}>Sunting</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {id}
                </li>
              </ol>
            </nav>
            <h1>Edit Dataset</h1>
          </div>
        </div>
        <div class="container">
          {isUpdated && <Alert message="Success, dataset telah diperbarui" type="success" showIcon />}
          <div className={`dc-page-form`} style={{ marginTop: "5%" }}>
            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">@type</div>
                <div class="label-description">
                  Jenis data di upload, apabila anda memilih <b>Infografis</b>, data yang diupload wajib berupa gambar.
                </div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Radio.Group options={optionsType} onChange={handleChangeType} value={type} optionType="button" />
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Dataset</div>
                <div class="label-description">
                  Unggah satu atau lebih file dataset, pastikan tidak ada kata sandi dalam file yang diunggah. Format <b>.csv</b> meruakan format paling direkomendasikan.
                </div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Upload name="avatar" listType="picture-card" fileList={fileList} beforeUpload={beforeUpload} onChange={handleUploadFile} onRemove={handleRemove}>
                  {fileList.length >= 5 ? null : uploadButton}
                </Upload>
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Pemilik Data</div>
                <div class="label-description">Pilih Organisasi, Biro, Bagian, atau Satker mana data ini berasal ?</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Select mode="tags" style={{ width: "100%" }} placeholder="Organisasi" onChange={handleChangeOrg} defaultValue={org}>
                  {initialOrg}
                </Select>
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Judul</div>
                <div class="label-description">Judul berita atau dataset yang akan dipublikasikan.</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Input value={title} placeholder="Judul dataset" onChange={onChangeTitle} />
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Deskripsi</div>
                <div class="label-description">Keterangan mengenai isi berita atau dataset yang akan dibagikan, deskripsi akan membantu dalam pencarian mesin pencari.</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <TextArea value={description} showCount maxLength={1000} placeholder="Keterangan singkat" onChange={onChangeDescription} />
                {/* </TextArea> */}
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Topik</div>
                <div class="label-description">Pilih satu atau lebih topik berita (tema) dari dataset yang akan dipublikasi.</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Select mode="tags" style={{ width: "100%" }} placeholder="Topik atau tema" defaultValue={topic} onChange={handleChangeTopic}>
                  {initialTopic}
                </Select>
              </div>
            </div>

            <div class="form-input-group row">
              <div class="col-md-3">
                <div class="label-title">Tags, Keyword</div>
                <div class="label-description">Lengkapi dataset dengan satu atau lebih kata kunci agar mudah ditemukan oleh mesin pencari.</div>
              </div>
              <div class="col-md-9 dkan-form-input">
                <Select mode="tags" style={{ width: "100%" }} placeholder="Kata kunci pencarian" onChange={handleChangeKeyword} defaultValue={keyword}>
                  {initialKeyword}
                </Select>
              </div>
            </div>

            <Button type="primary" onClick={handleAddDataset}>
              Kirim
            </Button>
            <Button style={{ marginLeft: "10px" }}>Reset</Button>
            <br />
            <br />
            <br />
          </div>
        </div>
      </Layout>
    )
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "22%" }}>
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
}

export default Edit
