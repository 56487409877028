import React from "react"
import { Announcement } from "../../resources/data-catalog-components"
import Layout from "../../components/Layout"
import config from "../../assets/config"
import { Link } from "@reach/router"

const About = ({ path }) => (
  <Layout title="About">
    <div class="breadcrumb-container-outer">
      <div class="container breadcrumb-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to={`/`}>Home</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Tentang
            </li>
          </ol>
        </nav>
        <h1>Open Data KPU</h1>
      </div>
    </div>
    <div className={`dc-page ${config.container}`}>
      <div class="paragraf">
        <a href="https://opendata.kpu.go.id/"> Opendata.kpu.go.id </a> merupakan Portal Satu Data Komisi Pemilihan Umum yang menyajikan data-data dari seluruh bidang di Komisi Pemilihan Umum. <a href="https://opendata.kpu.go.id/"> opendata.kpu.go.id </a> menyediakan data yang mudah dicari, diakses
        serta dapat digunakan kembali, dengan harapan publik/masyarakat pengguna portal dapat memanfaatkan data yang telah tersedia untuk menciptakan inovasi dan peran serata dalam membangun negara Indonesia menjadi lebih baik.{" "}
      </div>
      <div class="paragraf">
        Harapannya, <a href="https://opendata.kpu.go.id/"> opendata.kpu.go.id </a> menjadi Portal Data Terpadu menjadi Portal Data yang akurat, mutakhir, terbuka, terintegrasi, lengkap, akuntabel, dinamis, handal, valid, mudah diakses dan berkelanjutan, dan dapat menyajikan data dan informasi yang
        dibutuhkan masyarakat serta memenuhi hak publik sebagai bentuk penerapan e-government dalam rangka mewujudkan tata kelola pemerintahan yang transparan dan akuntabel.
      </div>
      <div class="paragraf">
        Sesuai dengan Undang-Undang Nomor 14 Tahun 2008 tentang Keterbukaan Informasi Publik, maka seluruh dataset/kumpulan data yang disajikan dalam Portal Data Terpadu Komisi Pemilihan Umum <a href="https://opendata.kpu.go.id/"> opendata.kpu.go.id </a> dapat dikategorikan sebagai domain publik,
        sehingga data yang tersaji tidak diperkenankan mengandung informasi yang mengandung rahasia negara, rahasia pribadi atau hal-hal lainnya yang telah diatur dalam Undang-Undang tersebut.
      </div>
    </div>
  </Layout>
)

export default About
