var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")
var _reactFontawesome = require("@fortawesome/react-fontawesome")

Object.defineProperty(exports, "__esModule", {
  value: true
})
exports["default"] = void 0

var _react = _interopRequireDefault(require("react"))

var _propTypes = _interopRequireDefault(require("prop-types"))

function TopicIcon(_ref) {
  var title = _ref.title.toLowerCase(),
    size = _ref.size,
    fill = _ref.fill

  switch (title) {
    case "Health":
      return _react["default"].createElement(
        "svg",
        {
          xmlns: "http://www.w3.org/2000/svg",
          width: size,
          height: size,
          viewBox: "0 0 100 100"
        },
        _react["default"].createElement("path", {
          fill: fill,
          d:
            "M78.886 78.233v-7.817c0-6.065-5.017-11.083-11.083-11.083-6.067 0-11.084 5.018-11.084 11.083v17.501c0 3.5-2.917 6.416-6.417 6.416s-6.417-2.916-6.417-6.416v-31.15c6.533-.583 12.951-3.267 17.966-8.283 10.734-10.733 11.201-26.717 1.518-38.85.233-.7.35-1.4.35-2.217 0-3.5-2.916-6.417-6.416-6.417-3.501 0-6.417 2.917-6.417 6.417s2.916 6.417 6.417 6.417c1.05 0 1.983-.233 2.917-.7 7.583 10.15 7.116 23.217-1.634 31.967-9.334 9.333-24.618 9.45-33.834.117-8.75-8.75-9.333-21.934-1.633-32.083.816.467 1.867.7 2.917.7 3.5 0 6.417-2.917 6.417-6.417s-3.15-6.417-6.65-6.417c-3.5 0-6.417 2.917-6.417 6.417 0 .817.117 1.517.35 2.217-9.567 12.017-8.983 28.35 1.517 38.967 5.017 5.017 11.316 7.701 17.967 8.167v31.15C39.219 93.983 44.235 99 50.302 99s11.083-5.017 11.083-11.083V70.416c0-3.499 2.916-6.416 6.417-6.416 3.5 0 6.416 2.917 6.416 6.416v7.817c-4.666 1.05-8.167 5.25-8.167 10.267 0 5.833 4.667 10.5 10.5 10.5 5.834 0 10.5-4.667 10.5-10.5.001-5.017-3.498-9.217-8.165-10.267zM55.553 7.417c0-.933.816-1.75 1.75-1.75.933 0 1.75.817 1.75 1.75 0 .934-.817 1.75-1.75 1.75-.934 0-1.75-.817-1.75-1.75zm-29.751-1.75c.933 0 1.75.817 1.75 1.75 0 .934-.817 1.75-1.75 1.75-.934 0-1.75-.817-1.75-1.75 0-.934.817-1.75 1.75-1.75zm50.75 88.666c-3.266 0-5.833-2.566-5.833-5.833s2.567-5.834 5.833-5.834c3.268 0 5.834 2.567 5.834 5.834s-2.567 5.833-5.834 5.833z"
        })
      )

    case "dapil":
    case "wilayah":
    case "data wilayah":
      return _react["default"].createElement("img", {
        src: window.location.origin + "/frontend/build/icon/wilayah.png"
      })

    case "phpu":
    case "hukum":
    case "sengketa":
      return _react["default"].createElement("img", {
        src: window.location.origin + "/frontend/build/icon/hukum.png"
      })

    case "situng":
    case "penghitungan":
    case "penghitsu":
    case "hitsu":
    case "tungsura":
    case "penghitungan dan penatapan suara":
      return _react["default"].createElement("img", {
        src: window.location.origin + "/frontend/build/icon/tungsura.png"
      })

    case "data pemilih":
    case "daftar pemilih":
    case "dpt":
    case "dps":
      return _react["default"].createElement("img", {
        src: window.location.origin + "/frontend/build/icon/datapemilih.png"
      })

    case "pencalonan":
    case "partai politik":
      return _react["default"].createElement("img", {
        src: window.location.origin + "/frontend/build/icon/parpol.png"
      })

    case "sdm":
    case "adhoc":
      return _react["default"].createElement("img", {
        src: window.location.origin + "/frontend/build/icon/sdm.png"
      })

    case "logistik":
      return _react["default"].createElement("img", {
        src: window.location.origin + "/frontend/build/icon/logistik.png"
      })

    case "City Planning":
    default:
      return _react["default"].createElement("img", {
        src: window.location.origin + "/frontend/build/icon/default.png"
      })
  }
}

TopicIcon.defaultProps = {
  title: "City Planning",
  size: 24,
  fill: "#000"
}
TopicIcon.propTypes = {
  title: _propTypes["default"].string,
  size: _propTypes["default"].number,
  fill: _propTypes["default"].string
}
var _default = TopicIcon
exports["default"] = _default
