import React from "react";

const NotFound = () => {
  return (
    <h1 style={{ fontWeight: "100", textAlign: "center", marginTop: "21%" }}>
      404 | Page not found.
    </h1>
  );
};

export default NotFound;
