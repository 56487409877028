"use strict";

var _lodash = require("lodash");
var _router = require("@reach/router");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
var _react = _interopRequireWildcard(require("react"));
var _excerpts = _interopRequireDefault(require("excerpts"));
var _Text = _interopRequireDefault(
  require("../../resources/data-catalog-components/lib/components/Text")
);
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _FormatIcon = _interopRequireDefault(
  require("../../resources/data-catalog-components/lib/components/FormatIcon")
);
var getUniqueFormats = function getUniqueFormats(formats) {
  var unique = [];
  return formats.reduce(function (a, b) {
    if (unique.indexOf(b[1].format) === -1) {
      unique.push(b[1].format);
      a.push(b[1]);
    }

    return a;
  }, []);
};

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(
    nodeInterop
  ) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (
    obj === null ||
    (_typeof(obj) !== "object" && typeof obj !== "function")
  ) {
    return { default: obj };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor =
    Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor
        ? Object.getOwnPropertyDescriptor(obj, key)
        : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj["default"] = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}

function tags(tags) {
  if (!tags) {
    return null;
  }

  return tags.map(function (dist) {
    return _react["default"].createElement(
      "span",
      {
        className: "tags-label",
      },
      _react["default"].createElement(
        _router.Link,
        {
          to: "/search/?keyword=" + dist.data,
        },
        dist.data
      )
    );
  });

  return null;
}

function bulan(bln) {
  switch (bln) {
    case "01":
      return "Jan";

    case "01":
      return "Feb";

    case "02":
      return "Mar";

    case "03":
      return "Januari";

    case "04":
      return "Apr";

    case "05":
      return "Mei";

    case "06":
      return "Jun";

    case "07":
      return "Jul";

    case "08":
      return "Ags";

    case "09":
      return "Sep";

    case "10":
      return "Okt";

    case "11":
      return "Nov";

    case "12":
      return "Des";
  }
}

function formats(distribution) {
  if (!distribution) {
    return null;
  }

  if (
    (0, _typeof2["default"])(distribution) === "object" ||
    Array.isArray(distribution)
  ) {
    var distributionWithUniqueFormats = getUniqueFormats(
      Object.entries(distribution)
    );

    var counted = (0, _lodash.countBy)(distribution, function (d) {
      return d.format;
    });
    return distributionWithUniqueFormats.map(function (dist) {
      var type = dist.data.mediaType ? dist.data.mediaType.split("/") : "";
      var backup = type ? type : "data";
      var format = dist.data.format ? dist.data.format : backup;
      return _react["default"].createElement(
        "div",
        null,
        _react["default"].createElement(_FormatIcon["default"], {
          format: format,
        })
      );
    });
  }

  return null;
}

var FeaturedContent = function SearchContent(datasets) {
  var items = datasets.datasets;
  return _react["default"].createElement(
    "div",
    {
      className: "dc-results-list",
      style: { borderBottom: "none" },
    },
    items &&
      items.map(function (item) {
        var publisher = "";
        if ("publisher" in item) {
          publisher = ", by " + item.publisher.data.name;
        }

        return _react["default"].createElement(
          "li",
          {
            key: item.identifier,
            style: { listStyleType: "none" },
          },
          _react["default"].createElement(
            "div",
            {
              className: "dc-search-list-item row",
            },
            _react["default"].createElement(
              "h2",
              { className: "col-md-12" },
              _react["default"].createElement(
                _router.Link,
                {
                  to: "/dataset/" + item.identifier,
                },
                item.title
              )
            ),
            _react["default"].createElement(
              "div",
              {
                className: "col-md-10",
                style: {
                  marginTop: "-10px",
                  marginBottom: "5px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#777",
                },
              },
              "Posted on " +
                item.modified.split("-")[2] +
                " " +
                bulan(item.modified.split("-")[1]) +
                " " +
                item.modified.split("-")[0] +
                publisher
            ),
            _react["default"].createElement(
              "div",
              {
                className: "col-md-10",
              },
              item.description &&
                _react["default"].createElement(
                  "div",
                  {
                    className: "dc-item-description",
                  },
                  _react["default"].createElement(
                    _Text["default"],
                    null,
                    (0, _excerpts["default"])(item.description, {
                      words: 35,
                    })
                  )
                )
            ),
            _react["default"].createElement(
              "div",
              {
                className: "col-md-2",
              },
              _react["default"].createElement(
                "div",
                {
                  className: "format-types",
                },
                formats(item.distribution)
              )
            ),
            _react["default"].createElement(
              "div",
              {
                className: "col-md-12",
                style: { marginTop: "15px", marginLeft: "-5px" },
              },
              _react["default"].createElement(
                "div",
                {
                  className: "tags",
                },
                tags(item.keyword)
              )
            )
          )
        );
      })
  );
};

var _default = FeaturedContent;
export default _default;
