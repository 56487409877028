import React from "react"
import ReactGA from "react-ga"
import { Link } from "@reach/router"
import Loader from "react-loader-advanced"
import LoadingSpin from "react-loading-spin"
import { ApiDocs, Organization, Text, Table, Tags, TopicIcon, TopicWrapper } from "../../resources/data-catalog-components"
import config from "../../assets/config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import Layout from "../../components/Layout"

let orgs = []
const ApiDocsSpecific = ({ id, location }) => {
  const { state } = location
  const [item, setItem] = React.useState(state && state.dataset ? state.dataset : {})
  const [loading, setLoading] = React.useState(true)
  const tag = "keyword" in item ? item.keyword : []
  const theme = "theme" in item ? item.theme : []

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  React.useEffect(() => {
    if (state && state.dataset) {
      setLoading(false)
    } else {
      axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${id}?show-reference-ids`).then((res) => {
        setItem(res.data)
        setLoading(false)
      })
    }
  }, [id, state])

  const orgName = "publisher" in item && item.publisher.data ? item.publisher.data.name : ""
  const orgDetails = orgs.filter((org) => orgName === org.name)
  const orgImage = orgDetails.length && orgDetails[0].imageUrl ? orgDetails[0].imageUrl : ""
  const orgDesc = orgDetails.length && orgDetails[0].description ? orgDetails[0].description : ""
  let renderOrg
  if (orgDetails.length > 0 && orgDetails[0].imageUrl) {
    renderOrg = <Organization name={orgName} imageUrl={orgImage} description={orgDesc} />
  } else {
    renderOrg = <Organization name={orgName} description={orgDesc} />
  }
  return (
    <Layout title="Dataset API">
      <div class="breadcrumb-container-outer" style={{ marginBottom: "0px !important" }}>
        <div class="container breadcrumb-container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to={`/`}>Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <Link to={`/search`}>Dataset</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <Link to={`/dataset/${id}`} state={{ dataset: { ...item } }}>
                  {item.identifier}
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                api
              </li>
            </ol>
          </nav>
          <h1>{item.title}</h1>
        </div>
      </div>

      <div className={`dc-dataset-page ${config.container}`}>
        <div className="col-md-12 dataset-info">
          <div class="row">
            <div class="col-md-3">
              <h3> Update Terakhir </h3>
              {item.modified}
            </div>

            <div class="col-md-3">
              <h3> Organisasi </h3>
              <a style={{ fontWeight: "bold", color: "#FF5500" }} href={"/search?publisher__name=" + orgName}>
                {orgName}{" "}
              </a>
            </div>

            <div class="col-md-3">
              <h3> Topik </h3>
              <Tags tags={theme} path="/search?keyword=" label="Tags" />
            </div>

            <div class="col-md-3">
              <h3> Tags </h3>
              <Tags tags={tag} path="/search?keyword=" label="Tags" />
            </div>
          </div>
        </div>
        <Loader backgroundStyle={{ backgroundColor: "#f9fafb" }} foregroundStyle={{ backgroundColor: "#f9fafb" }} show={loading} message={<LoadingSpin width={"3px"} primaryColor={"#007BBC"} />}>
          <div className="row">
            <div className="results-list col-md-12 col-sm-12">
              <ApiDocs endpoint={`${process.env.REACT_APP_ROOT_URL}` + "/metastore/schemas/dataset/items/" + id + "/docs"} />
            </div>
          </div>
        </Loader>
      </div>
    </Layout>
  )
}

export default ApiDocsSpecific
