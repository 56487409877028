import Calendar from "color-calendar";
import "./styles.css";

var _typeof = require("@babel/runtime/helpers/typeof");
var _react = _interopRequireWildcard(require("react"));

var _search_defaults = require("../../resources/data-catalog-components/lib/services/search/search_defaults");

function join(t, s) {
  let a = [{ day: "2-digit" }, { month: "2-digit" }, { year: "numeric" }];

  function format(m) {
    let f = new Intl.DateTimeFormat("en", m);
    return f.format(t);
  }
  return a.map(format).join(s);
}

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(
    nodeInterop
  ) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (
    obj === null ||
    (_typeof(obj) !== "object" && typeof obj !== "function")
  ) {
    return { default: obj };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor =
    Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor
        ? Object.getOwnPropertyDescriptor(obj, key)
        : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj["default"] = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}

var CalendarComponent = function CalendarComponent() {
  var _useContext = (0, _react.useContext)(_search_defaults.SearchDispatch),
    dispatch = _useContext.dispatch;
  (0, _react.useEffect)(function () {
    let myCal = new Calendar({
      id: "#myCal",
      theme: "glass",
      weekdayType: "long-upper",
      monthDisplayType: "long",
      calendarSize: "small",
      layoutModifiers: ["month-left-align"],
      selectedDateClicked: () => {
        myCal.reset();
        dispatch({
          type: "UPDATE_DATE",
          data: {
            selectedDate: "",
          },
        });
      },
      dateChanged: (currentDate) => {
        var today = new Date();
        let s = join(currentDate, "-");
        let x = join(today, "-");
        if (s != x) {
          dispatch({
            type: "UPDATE_DATE",
            data: {
              selectedDate: s,
            },
          });
        }
      },
    });
  }, []);

  return _react["default"].createElement("div", {
    id: "myCal",
    className: "dc-results-list col-md-12 col-sm-12",
  });
};

export default CalendarComponent;
