import React from "react";
import axios from "axios";
import {
  IconList,
  IconListItem,
} from "../../resources/data-catalog-components";
import config from "../../assets/config";
import Layout from "../../components/Layout";
import { Link } from "@reach/router";

const Publisher = () => {
  const [themes, setThemes] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [input, setInput] = React.useState("");

  const onChange = (e) => {
    setInput(e.currentTarget.value);
  };

  React.useEffect(() => {
    async function getThemes() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/search/facets?facets=publisher__name`
      );
      var dataWithTerm = [];
      if (input !== "") {
        data.facets.forEach((o) => {
          let name = o.name.toLowerCase();
          if (name.includes(input.toLowerCase())) {
            dataWithTerm.push(o);
          }
        });

        setThemes(dataWithTerm);
      } else {
        setThemes(data.facets);
      }
    }
    getThemes();
  }, [input]);

  React.useEffect(() => {
    setItems(
      themes.map((topic) => {
        let item = {
          ref: "/search?publisher__name=" + topic.name,
          title: topic.name,
          total: topic.total,
          size: "100",
        };
        return item;
      })
    );
  }, [themes]);

  return (
    <Layout title="Organisasi">
      <div class="breadcrumb-container-outer">
        <div class="container breadcrumb-container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to={`/`}>Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Organisasi
              </li>
            </ol>
          </nav>
          <h1>Organisasi</h1>
        </div>
      </div>
      <div className={`dc-page ${config.container}`}>
        <input
          style={{ height: "49px" }}
          placeholder="Type your search term here"
          type="text"
          class="form-control-lg form-control"
          onChange={onChange}
        ></input>
        <div>
          <IconList
            items={items}
            component={IconListItem}
            paneTitle=""
            className="opendata-icon-list"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Publisher;
