"use strict"

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")

Object.defineProperty(exports, "__esModule", {
  value: true
})
exports["default"] = void 0

var _react = _interopRequireDefault(require("react"))

var _propTypes = _interopRequireDefault(require("prop-types"))

var _reactFontawesome = require("@fortawesome/react-fontawesome")

var _Menu = _interopRequireDefault(require("../../components/Menu"))

function Footer(_ref) {
  var links = _ref.links,
    customClasses = _ref.customClasses
  var menu1 = links
    ? _react["default"].createElement(_Menu["default"], {
        items: links.footer1,
        menuId: "leftnav"
      })
    : null
  var menu2 = links
    ? _react["default"].createElement(_Menu["default"], {
        items: links.footer2,
        menuId: "rightnav"
      })
    : null
  return _react["default"].createElement(
    "div",
    {
      className: "dc-footer"
    },
    _react["default"].createElement(
      "div",
      {
        className: "".concat(customClasses, " page-footer")
      },
      _react["default"].createElement(
        "div",
        {
          className: "branding"
        },
        _react["default"].createElement("h2", null, "Disclaimers"),
        _react["default"].createElement("p", null, "Masyarakat dapat menggunakan data yang tersedia dalam portal ini secara bebas  dengan tanpa mengubah isi serta mencantumkan sumber, segala bentuk pelanggaran akan ditindak secara hukum."),

        _react["default"].createElement(
          "div",
          {
            className: "social"
          },
          _react["default"].createElement(
            "a",
            {
              href: "https://www.facebook.com/KPURepublikIndonesia/"
            },
            _react["default"].createElement(_reactFontawesome.FontAwesomeIcon, {
              icon: ["fab", "facebook"],
              size: "1x",
              "aria-hidden": "true",
              role: "presentation"
            }),
            _react["default"].createElement(
              "span",
              {
                className: "sr-only"
              },
              "Facebook"
            )
          ),
          _react["default"].createElement(
            "a",
            {
              href: "https://twitter.com/kpu_id"
            },
            _react["default"].createElement(_reactFontawesome.FontAwesomeIcon, {
              icon: ["fab", "twitter"],
              size: "1x",
              "aria-hidden": "true",
              role: "presentation"
            }),
            _react["default"].createElement(
              "span",
              {
                className: "sr-only"
              },
              "Twitter"
            )
          ),
          _react["default"].createElement(
            "a",
            {
              href: "https://www.instagram.com/kpu_ri/"
            },
            _react["default"].createElement(_reactFontawesome.FontAwesomeIcon, {
              icon: ["fab", "instagram"],
              size: "1x",
              "aria-hidden": "true",
              role: "presentation"
            }),
            _react["default"].createElement(
              "span",
              {
                className: "sr-only"
              },
              "Github"
            )
          )
        )
      ),
      menu1,
      menu2
    )
  )
}

Footer.defaultProps = {
  customClasses: "",
  links: null
}
Footer.propTypes = {
  customClasses: _propTypes["default"].string,
  links: _propTypes["default"].arrayOf(
    _propTypes["default"].shape({
      label: _propTypes["default"].string,
      url: _propTypes["default"].string
    })
  )
}
var _default = Footer
exports["default"] = _default
