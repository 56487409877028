import React from "react"
import ReactGA from "react-ga"
import { Router } from "@reach/router"
import Home from "./templates/home"
import About from "./templates/about"
import SearchTemplate from "./templates/search"
import NotFound from "./templates/not_found"
import Dataset from "./templates/dataset"
import ApiDocsSpecific from "./templates/dataset/api"
import Publishers from "./templates/publishers"
import Topics from "./templates/topics"
import Admin from "./templates/admin"
import Add from "./templates/admin/add"
import Edit from "./templates/admin/edit"
import Infographics from "./templates/infographics"
import "@civicactions/data-catalog-components/dist/index.css"
import "./theme/index.scss"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"

ReactGA.initialize("UA-208520246-1")

library.add(fab, fas)

function App() {
  return (
    <Router>
      <NotFound default />
      <Home path="/" />
      <About path="/about" />
      <Publishers path="/publishers" />
      <Topics path="/topics" />
      <Admin path="/manage" />
      <Add path="/manage/add" />
      <Edit path="/manage/edit/:id" />
      <Infographics path="/infographics" />
      <SearchTemplate path="/search" />
      <Dataset path="/dataset/:id" />
      <ApiDocsSpecific path="/dataset/:id/api" />
    </Router>
  )
}

export default App
