import React from "react"
import Helmet from "react-helmet"
import { Link, navigate } from "@reach/router"
import { Header, NavBar, Footer } from "../../resources/data-catalog-components"
import config from "../../assets/config.json"
import links from "../../assets/menu.json"
import axios from "axios"
import { Modal, Input } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"

axios.defaults.withCredentials = true

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000))
}

const Layout = ({ children, title, description }) => {
  const [isLogin, setIsLogin] = React.useState(false)
  const [isProcess, setIsProcess] = React.useState(false)
  const [loginTxt, setLoginTxt] = React.useState("Login")
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const handleLogin = () => {
    setIsLogin(true)
    setLoginTxt("Logout")
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  function onChangeUsername(e) {
    setUsername(e.target.value)
  }

  function onChangePassword(e) {
    setPassword(e.target.value)
  }

  const handleLogout = async () => {
    setIsProcess(true)
    try {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/user/logout?_format=json&token=` + localStorage.getItem("logout_token"))
      setIsProcess(false)
      localStorage.clear()
      setLoginTxt("Login")
      navigate(`/`)
    } catch (error) {
      // console.error(error);
    }
    setIsLogin(false)
  }

  React.useEffect(() => {
    if (localStorage.getItem("authenticated") === "true") {
      setLoginTxt("Logout")
    } else {
      setLoginTxt("Login")
    }

    if (isLogin) {
      setIsProcess(true)

      // var data = JSON.stringify({ name: "admin", pass: "KYSJERrqWM" });
      var data = JSON.stringify({ name: username, pass: password })
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/user/login?_format=json`,
        headers: {
          "Content-Type": "application/json"
        },
        data: data
      }

      axios(config)
        .then(function (response) {
          localStorage.setItem("authenticated", true)
          localStorage.setItem("logout_token", response.data.logout_token)
          localStorage.setItem("token", response.data.csrf_token)
          localStorage.setItem("expired", Date.now())
          setIsProcess(false)
          setIsModalVisible(false)
          setLoginTxt("Logout")
          navigate(`/manage`)
        })
        .catch(function (error) {
          setIsModalVisible(false)
          setIsProcess(false)
        })

      simulateNetworkRequest().then(() => {
        setIsLogin(false)
      })
    }
  }, [isLogin, setLoginTxt])

  return (
    <div className="App">
      <Helmet
        title={`${title} - Open Data Komisi Pemilihan Umum`}
        description={description}
        defer={false}
        htmlAttributes={{
          lang: "id"
        }}
      />

      <Modal title="Otentifikasi" visible={isModalVisible} onOk={handleLogin} onCancel={handleCancel} okText="Login">
        <Input value={username} placeholder="Username" onChange={onChangeUsername} style={{ marginBottom: "8px", height: "50px" }} />{" "}
        <Input.Password placeholder="password" onChange={onChangePassword} style={{ marginBottom: "5px", height: "50px" }} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
      </Modal>

      <div class="main-header">
        <div class="container">
          <div class="d-flex flex-row flex-fill bd-highlight">
            <div class="p-1 bd-highlight">
              <Header logo={config.logo} />
            </div>
            <div class="p-1 ml-auto flex-fill bd-highlight">
              <NavBar
                navItems={links.main.map((item) => {
                  if (item.label === "Login") {
                    if (loginTxt == "Login") {
                      return (
                        <div
                          style={{
                            border: "1px solid #FF5500",
                            marginTop: "19px",
                            lineHeight: "13px",
                            borderRadius: "3px",
                            height: "42px",
                            padding: "13px",
                            fontWeight: "400",
                            fontSize: "15px",
                            cursor: "pointer"
                          }}
                          onClick={showModal}
                        >
                          Login
                        </div>
                      )
                    } else {
                      return (
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            <Link to="/manage">Manage</Link>
                          </span>
                          <span
                            style={{
                              border: "1px solid #FF5500",
                              lineHeight: "13px",
                              width: "80px",
                              borderRadius: "3px",
                              height: "42px",
                              padding: "13px",
                              fontWeight: "400",
                              fontSize: "15px",
                              cursor: "pointer"
                            }}
                            onClick={handleLogout}
                          >
                            Logout
                          </span>
                        </div>
                      )
                    }
                  } else {
                    return (
                      <Link activeClassName="active" to={item.url}>
                        {item.label}
                      </Link>
                    )
                  }
                })}
                customClasses={config.container}
              />
            </div>
          </div>
        </div>
      </div>

      <main class="home-page">{children}</main>
      <Footer links={links} customClasses={config.container} />
      <div class="second-footer">
        <img src={window.location.origin + "/frontend/build/PUSDATIN_logo_white.png"} height="55px"></img>
        <div style={{ color: "#FFF", fontSize: "13px" }}></div>
      </div>
    </div>
  )
}

export default Layout
